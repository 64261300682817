@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "HKGrotesk-Regular";
  src: local("HKGrotesk-Regular"),
   url("./fonts/HKGrotesk-Regular.otf") format("truetype");
}


/* box when typing completed without validation error*/
.sfc-complete {
  /* background-color: #34fa29 !important; */
}
/* box when a validation error */
.sfc-invalid {
  border-color: 'red' !important;
  background-color: red !important;
}

input {
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #d3dce6;
  color: #4a5568;
  display: block;
  outline: none;
  padding: 5px 12px;
  width: 360px;
  font-family: Nunito Sans, sans-serif;
}
input:placeholder-shown {
  border: 1px solid #d3dce6;
}
input:hover {
  border: 1px solid #95aac1;
}
input:focus {
  border: 1px solid #00becf;
}


