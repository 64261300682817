.App {
  text-align: center;
  max-width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/************* text boxes styles ****************/
/* empty box without focus*/
.sfc-empty {
  /* background-color: #fecd58 !important; */
}

/* empty box with focus */
.sfc-empty.sfc-focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
  /* background-color: #fe8423 !important; */
}

/* box with focus when typing */
.sfc-focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
  /* background-color: #feb1c7 !important; */
}

/* box when typing completed without validation error*/
.sfc-complete {
  /* background-color: #34fa29 !important; */
}
/* box when a validation error */
.sfc-invalid {
  border-color: 'red' !important;
  background-color: red !important;
}

/*********** base Nuvei 3D-Secure mode styles ************/
.SfcFields--webkit-autofill {
  background-color: #fefde5 !important;
}
.Modal.fade .sfcModal-dialog {
            transform: translate(0, -25%);
            transition: all .3s ease-out;
            opacity: 0;
}
.Modal.is-in .sfcModal-dialog {
            transform: translate(0, 0);
            opacity: 1;
}
.sfcModal-header {
            height: 1.5rem;
}
.sfcModal-dialog {
            margin: 55px auto;
            max-width: 492px;
            position: relative;
            width: auto;
}
.sfcModal-content {
            position: relative;
            background-color: #fff;
            background-clip: padding-box;
            outline: 0;
            border: 1px solid #dfdfdf;
}
.sfcModal-close {
            font-size: .9rem;
            color: #2c2a2a;
            position: absolute;
            top: 0.4rem;
            right: 0.5rem;
            border: 0;
            padding: 0;
            cursor: pointer;
}
.sfcIcon--close:before {
            content: "2716";
}
[class*=" sfcIcon--"]:before, [class^=sfcIcon--]:before {
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
}
.sfcModal-body {
            -webkit-overflow-scrolling: touch;
}